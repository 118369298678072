import { Component } from '@angular/core';

@Component({
  selector: 'orderadmin-frontend-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent{

}
