<mat-sidenav #sideDrawer mode="over" position="end" class="p-1">
  <div class="grid-container">
    <div class="grid-title pl-2">
      <h2>{{ 'inhouse.settingsTabLabel' | translate }}</h2>
      <button
        mat-icon-button
        style="float: right"
        (click)="closeSideDrawer()"
        style="font-size: 24px"
      >
        <i class="fa-solid fa-x closeIcon"></i>
      </button>
    </div>
    <div class="grid-item" class="pt-3 pl-2">
      <h2>Layout</h2>
    </div>
    <div class="grid-item pl-2">
      <h3>Show Extra Title</h3>
      <mat-slide-toggle
        style="padding-right: 4%"
        color="primary"
        class="module__toggle"
        [(ngModel)]="extraSettingsValue"
        (change)="onStatusChange($event)"
      ></mat-slide-toggle>
    </div>
    <div class="grid-item pl-2">
      <h2>Filters</h2>
    </div>
    <div class="grid-item pl-2 mb-0">
      <h3>Filters by Area</h3>
    </div>

    <mat-form-field class="pl-1">
      <mat-label *ngIf="selectedAreas.length === 0"
        ><h3>{{ 'ordersdashboard.allAreasLabel' | translate }}</h3></mat-label
      >
      <mat-select
        #select
        (selectionChange)="onQrCodeAreaChange($event)"
        [(ngModel)]="selectedAreas"
        multiple
      >
        <mat-option *ngFor="let area of qrCodeAreas" [value]="area">
          {{ area.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-sidenav>
