import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RoleState {
  private readonly _roles = new BehaviorSubject<string[]>([]);
  public readonly roles$ = this._roles.asObservable();

  public setRoles(roles: string[]): void {
    this._roles.next(roles);
  }
}
