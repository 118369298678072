import { JsonProperty } from 'json-object-mapper';
import { BusinessSubcategory } from './business-subcategory.model';

export class BusinessType {
  @JsonProperty() public businessTypeId: number;
  @JsonProperty() public name: string;
  @JsonProperty({ type: BusinessSubcategory })
  public subCategories: BusinessSubcategory[];

  constructor() {
    this.businessTypeId = null as unknown as number;
    this.name = '';
    this.subCategories = [];
  }
}
