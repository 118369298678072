export interface NavItem extends BottomNavItem {
  link: string;
}

export interface BottomNavItem {
  text: string;
  iconClass: string;
  isSvg?: boolean;
  key?: string;
  openNewTab?: boolean;
  requiredRole?: string | string[];
}
