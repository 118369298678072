import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { AvatarComponent } from './avatar/avatar.component';
import { CustomInputComponent } from './custom-input/custom-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';
import { FormWrapperComponent } from './form-wrapper/form-wrapper.component';
import { PromptDialogComponent } from "./prompt-dialog/prompt-dialog.component";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

const SHARED_COMPONENTS = [
  CustomInputComponent,
  AvatarComponent,
  LoaderComponent,
  FormWrapperComponent,
  PromptDialogComponent
];

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [...SHARED_COMPONENTS],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: localStorage.getItem('lng') || 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),],
  exports: [...SHARED_COMPONENTS],
})
export class SharedComponentModule { }
