import { Injectable } from '@angular/core';
import { RoleState } from '../states/role.state';

@Injectable({ providedIn: 'root' })
export class RoleFacade {
  public readonly roles$ = this._roleState.roles$;
  public readonly setRoles = this._roleState.setRoles.bind(this._roleState);

  constructor(private readonly _roleState: RoleState) {}
}
