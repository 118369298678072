<div
  class="absolute inset-0 flex-wrap flex justify-center items-center md:mx-2.5 md:my-2.5"
>
  <div class="mt-5 business__form">
    <div class="mb-10">
      <p
        class="tracking-wide font-semibold text-3xl font-sans text-primaryFontColor"
      >
        Dein Unternehmen
      </p>
      <p class="text-medium font-sans text-primaryFontColor tracking-wide">
        Mit diesem Formular erstellst du dein Unternehmen.
      </p>
    </div>

    <div [formGroup]="createBusinessForm">
      <div class="inline-flex">
        <div class="mt-8">
          <p class="text-primaryFontColor m-0">Unternehmensart</p>
          <mat-form-field appearance="outline">
            <mat-select
              formControlName="businessType"
              placeholder="Auswählen"
              class="selection"
            >
              <mat-option
                *ngFor="
                  let businessType of businessFacade.businessTypes$.data$
                    | async
                "
                [value]="businessType.businessTypeId"
              >
                {{ businessType.name }}
              </mat-option>
            </mat-select>

            <mat-error class="mt-3" *ngIf="isInvalid('businessType')"
              >{{ 'global.field_mandatory' | translate }}.</mat-error
            >
          </mat-form-field>
        </div>

        <div *ngIf="subcategories?.length" class="mt-8 ml-5">
          <p class="text-primaryFontColor m-0">Unternehmenskategorie</p>
          <mat-form-field appearance="outline">
            <mat-select
              formControlName="businessTypeCategoryId"
              placeholder="Auswählen"
              class="selection"
            >
              <mat-option
                *ngFor="let subcategory of subcategories"
                [value]="subcategory.businessTypeCategoryId"
              >
                {{ subcategory.name }}
              </mat-option>
            </mat-select>

            <mat-error class="mt-3" *ngIf="isInvalid('businessTypeCategoryId')"
              >{{ 'global.field_mandatory' | translate }}.</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <div class="mt-8">
        <p class="text-primaryFontColor m-0">Unternehmensname</p>
        <mat-form-field appearance="outline">
          <input
            type="text"
            matInput
            placeholder=""
            formControlName="businessName"
            autocomplete="off"
          />
          <mat-error class="mt-3" *ngIf="isInvalid('businessName')"
            >{{ 'global.field_mandatory' | translate }}.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="mt-8">
        <p class="text-primaryFontColor m-0">Straße & Nr.</p>
        <mat-form-field appearance="outline">
          <input
            type="text"
            matInput
            formControlName="streetName"
            autocomplete="off"
          />
          <mat-error class="mt-3" *ngIf="isInvalid('streetName')"
            >{{ 'global.field_mandatory' | translate }}.</mat-error
          >
        </mat-form-field>
      </div>

      <div class="inline-flex">
        <div class="mt-8">
          <p class="text-primaryFontColor m-0">Postleitzahl</p>
          <mat-form-field appearance="outline">
            <input
              type="num"
              matInput
              placeholder=""
              formControlName="postalCode"
              autocomplete="off"
            />
            <mat-error class="mt-3" *ngIf="isInvalid('postalCode')"
              >{{ 'global.field_mandatory' | translate }}.</mat-error
            >
          </mat-form-field>
        </div>

        <div class="mt-8 ml-5">
          <p class="text-primaryFontColor m-0">Stadt</p>
          <mat-form-field appearance="outline">
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="city"
              autocomplete="off"
            />
            <mat-error class="mt-3" *ngIf="isInvalid('city')"
              >{{ 'global.field_mandatory' | translate }}.</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <div class="mt-8">
        <p class="text-primaryFontColor m-0">Land</p>
        <mat-form-field appearance="outline">
          <mat-select placeholder="Auswählen" formControlName="country">
            <mat-option
              *ngFor="let country of businessFacade.countries$.data$ | async"
              [value]="country.countryId"
            >
              {{ country.name }}
              <div
                class="last-highlighted"
                *ngIf="country.isLastHighlighted"
              ></div>
            </mat-option>
          </mat-select>
          <mat-error class="mt-3" *ngIf="isInvalid('country')"
            >{{ 'global.field_mandatory' | translate }}.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="inline-flex mt-8">
        <div>
          <p class="text-primaryFontColor m-0">Vorname des Eigentümers</p>
          <mat-form-field appearance="outline">
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ownerFirstName"
              autocomplete="off"
            />
            <mat-error class="mt-3" *ngIf="isInvalid('ownerFirstName')"
              >{{ 'global.field_mandatory' | translate }}.</mat-error
            >
          </mat-form-field>
        </div>

        <div class="ml-5">
          <p class="text-primaryFontColor m-0">Nachname des Eigentümers</p>
          <mat-form-field appearance="outline">
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ownerLastName"
              autocomplete="off"
            />
            <mat-error class="mt-3" *ngIf="isInvalid('ownerLastName')"
              >{{ 'global.field_mandatory' | translate }}.</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <div class="mt-8">
        <p class="text-primaryFontColor m-0">Passwort des Eigentümers</p>
        <mat-form-field appearance="outline">
          <input
            type="password"
            matInput
            placeholder=""
            formControlName="ownerPassword"
            [pattern]="passwordPattern"
            autocomplete="off"
          />
          <mat-error class="mt-3" *ngIf="isInvalid('ownerPassword')"
            >Das Passwort muss mindestens 8 Zeichen lang sein und einen
            Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein
            Sonderzeichen enthalten.</mat-error
          >
        </mat-form-field>
      </div>

      <div class="mt-8">
        <p class="text-primaryFontColor m-0">E-Mail-Adresse des Eigentümers</p>
        <mat-form-field appearance="outline">
          <input
            type="email"
            matInput
            placeholder=""
            formControlName="ownerEmail"
            autocomplete="off"
            required
          />
          <mat-error class="mt-3" *ngIf="isInvalid('ownerEmail')"
            >{{ 'global.field_mandatory' | translate }}.</mat-error
          >
        </mat-form-field>
      </div>
      <hr class="mb-5 mt-5" />
      <div class="text-right mb-10">
        <button
          [disabled]="
            disableButton || (businessFacade.businessId$.isLoading$ | async)
          "
          color="primary"
          mat-raised-button
          [attr.aria-label]="'Continue Button'"
          type="submit"
          (click)="onSubmit()"
        >
          Fortsetzen
        </button>
      </div>
    </div>
  </div>
</div>
