// @ts-nocheck
import { JsonProperty } from 'json-object-mapper';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  Observable,
  Subscription,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export class StateData<T> {
  @JsonProperty() public data: BehaviorSubject<T>;
  @JsonProperty() public error: BehaviorSubject<boolean>;
  @JsonProperty() public isLoading: BehaviorSubject<boolean>;
  @JsonProperty() public retry: BehaviorSubject<void>;
  @JsonProperty() public subscription: Subscription | null;

  constructor(initialValue?: any) {
    this.data = new BehaviorSubject<T>(initialValue || null);
    this.error = new BehaviorSubject<boolean>(false);
    this.isLoading = new BehaviorSubject<boolean>(false);
    this.retry = new BehaviorSubject<void>(null);
    this.subscription = null;
  }

  public reset(): void {
    this.data.next(null);
    this.error.next(false);
    this.isLoading.next(false);
    this.retry.next(null);
    this.subscription = null;
  }

  public asObservable = (): {
    data$: Observable<T>;
    error$: Observable<boolean>;
    isLoading$: Observable<boolean>;
    isNotLoading$: Observable<boolean>;
    hasData$: Observable<boolean>;
    hasNoData$: Observable<boolean>;
  } => ({
    data$: this.data.asObservable().pipe(distinctUntilChanged()),
    error$: this.error.asObservable().pipe(distinctUntilChanged()),
    isLoading$: this.isLoading.asObservable().pipe(distinctUntilChanged()),
    isNotLoading$: this.isLoading
      .asObservable()
      .pipe(map((isLoading) => !isLoading)),
    hasData$: this.data
      .asObservable()
      .pipe(
        map((data) =>
          data instanceof Array ? data?.length > 0 : Boolean(data)
        )
      )
      .pipe(distinctUntilChanged()),
    hasNoData$: this.data
      .asObservable()
      .pipe(
        map(
          (data) => !(data instanceof Array ? data?.length > 0 : Boolean(data))
        )
      )
      .pipe(distinctUntilChanged()),
  });

  public fetch(
    fetchCall: (...params: (string | number | void)[]) => Observable<T>,
    args: (string | number)[]
  ): void {
    this.subscription?.unsubscribe();
    this.error.next(false);
    this.isLoading.next(true);

    this.subscription = fetchCall(...args).subscribe(
      (data) => this.data.next(data),
      (err) => {
        this.error.next(true);
        // dispatch error
      }
    );
  }

  public fetchWithCombineLatest(
    fetchCall: (...params: (string | number | void)[]) => Observable<T>,
    args: Observable<string | number>[]
  ): void {
    this.subscription?.unsubscribe();
    this.error.next(false);
    this.isLoading.next(true);

    this.subscription = combineLatest([this.retry, ...args])
      .pipe(switchMap(([_, ...argsData]) => fetchCall(...argsData)))
      .subscribe({
        next: (data) => this.data.next(data),
        error: (err) => {
          console.error('error in StateData', err?.message, err);
          this.error.next(true);
        },
      });
  }
}
