import { NgModule } from '@angular/core';
import { ClickOutsideDirective } from './click-outside.directive';

const SHARED_COMPONENTS = [ClickOutsideDirective];

@NgModule({
  declarations: [...SHARED_COMPONENTS],
  exports: [...SHARED_COMPONENTS],
})
export class SharedDirectiveModule {}
