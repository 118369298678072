<div [class]="'counter-container ' + size">
  <div
    class="change-button decrease-button cursor-pointer"
    (click)="decrease()"
    [ngClass]="reachedLimitDecrease || counter === minValue ? 'disabled' : ''"
  >
    <i class="fas fa-minus"></i>
  </div>
  <p class="counter-number">{{ counter }}</p>
  <div
    class="change-button increase-button cursor-pointer"
    (click)="increase()"
    [ngClass]="reachedLimitIncrease || counter === maxValue ? 'disabled' : ''"
  >
    <i class="fas fa-plus"></i>
  </div>
</div>
