import { JsonProperty } from 'json-object-mapper';
import { Address } from './address.model';
import { BusinessType } from './business-type.model';

export class Customer {
  @JsonProperty() public businessId: number | null;
  @JsonProperty() public businessTypeId: number | null;
  @JsonProperty() public addressId: number | null;
  @JsonProperty() public name: string;
  @JsonProperty() public phoneNumber: string;
  @JsonProperty({ name: 'ownerEmail' }) public email: string;
  @JsonProperty() public ownerFirstName: string | undefined;
  @JsonProperty() public ownerLastName: string | undefined;
  @JsonProperty() public userId: string;
  @JsonProperty() public website: string;
  @JsonProperty() public currency: string;
  @JsonProperty() public createdDate: Date | null;
  @JsonProperty() public modifiedDate: Date | null;
  @JsonProperty({ type: BusinessType })
  public businessType: BusinessType | null;
  @JsonProperty({ type: Address }) public address: Address | null;

  constructor() {
    this.businessId = null;
    this.businessTypeId = null;
    this.addressId = null;
    this.name = '';
    this.email = '';
    this.website = '';
    this.currency = '';
    this.ownerFirstName = '';
    this.ownerLastName = '';
    this.userId = '';
    this.createdDate = null;
    this.modifiedDate = null;
    this.businessType = null;
    this.address = null;
    this.phoneNumber = '';
  }

  public get owner(): string {
    return `${this.ownerFirstName} ${this.ownerLastName}`;
  }

  public get city(): string {
    return this.address?.city as string;
  }

  public get businessTypeName(): string {
    return this.businessType?.name as string;
  }
}
