import { JsonProperty } from "json-object-mapper";

export class DeliveryZone {
    @JsonProperty() public deliveryZoneId: number;
    @JsonProperty() public zipCode: string;
    @JsonProperty() public city: string;
    @JsonProperty() public cost: number;
    @JsonProperty() public serviceTypeId: number;
    @JsonProperty() public businessId: number;
    @JsonProperty() public isUpdated: boolean;
    @JsonProperty() public isRemoved: boolean;

    constructor() {
        this.deliveryZoneId = null as unknown as number;
        this.zipCode = '';
        this.city = '';
        this.cost = null as unknown as number;
        this.serviceTypeId = null as unknown as number;
        this.businessId = null as unknown as number;
        this.isUpdated = false;
        this.isRemoved = false;
    }
}