<div class="flex items-center justify-center h-screen bg-bgColor">
  <div class="bg-white px-24 py-24">
    <img src="./assets/images/no-access.svg" alt="No Access Image" />
    <div class="mt-7">
      <p class="text-center text-primaryFontColor text-xl">
        Du hast keinen Zugriff auf diese Ressource.
      </p>
    </div>
  </div>
</div>
