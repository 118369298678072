<div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
  <div
    class="flex flex-col flex-grow border-r border-gray-200 pt-5 bg-white overflow-y-auto"
  >
    <div class="flex items-center justify-center flex-shrink-0 px-4">
      <img
        class="h-8 w-auto"
        src="./assets/images/logo.svg"
        alt="Jamatu logo"
      />
    </div>
    <div class="mt-5 flex-grow flex flex-col">
      <nav class="flex-1 px-2 pb-4 space-y-1 menu-item">
        <span
          (click)="goToLink(item)"
          class="group flex text-sm rounded-md py-2 px-2 item-center"
          *ngFor="let item of navItems"
          [ngClass]="navItemClasses(item.link, item.openNewTab)"
        >
          <span
            [ngClass]="item.iconClass"
            routerLinkActive="active-icon"
            class="text-base text-grayColor hover:text-primaryFontColor link-icon"
          >
          </span>
          <span class="ml-3 text-primaryFontColor">{{ item.text }}</span>
        </span>
      </nav>
    </div>
  </div>
  <div class="bg-white">
    <nav class="flex-1 px-2 pb-4 space-y-1">
      <span
        *ngFor="let item of bottomNavItems"
        (click)="setActive(item)"
        class="group flex text-sm rounded-md py-2 px-2 item-center widget menu-item menu-item__bottom"
        [ngClass]="{
          'bg-gray-100': isActive(item),
          'text-gray-900': isActive(item)
        }"
      >
        <span
        
          *ngIf="!item.isSvg; else svgIcon"
          [ngClass]="item.iconClass"
          class="text-base text-grayColor hover:text-primaryFontColor"
        >
        </span>

        <ng-template #svgIcon>
          <img
            [src]="getSvgUrl(item.iconClass)"
            alt="icon"
            class="menu-item__svg"
          />
        </ng-template>
        <span class="ml-3">{{ item.text }}</span>
      </span>
    </nav>
  </div>
</div>
