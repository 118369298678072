import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BusinessConfiguration } from '../models/business-configuration.model';
import { environment } from "../../../../../../apps/orderadmin/src/environments/environment";

@Injectable({
  providedIn: 'root',
})
export class BusinessConfigurationService {
  private readonly apiUrl = `${environment.API_URL}/BusinessConfiguration`;

  constructor(private readonly http: HttpClient) {}

  public getConfigurationByBusinessId(businessId: number): Observable<BusinessConfiguration> {
    return this.http.get<BusinessConfiguration>(`${this.apiUrl}/${businessId}`);
  }

  public saveOrUpdateConfiguration(businessConfiguration: BusinessConfiguration): Observable<void> {
    return this.http.post<void>(this.apiUrl, businessConfiguration);
  }
}
