import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { map, Observable, shareReplay, take } from 'rxjs';
import { ObjectMapper } from 'json-object-mapper';
import { Module } from './models/module.model';
import { UpdateModule } from './models/update-module.model';
import { OrderingTime } from './models/ordering-time.model';
import {PreparationTime} from "./models/preparation-time.model";
import { DeliveryZone } from './models/delivery-zone.model';
import { UpdateDeliveryZoneRequest } from './models/update-delivery-zone.model';
import { MinimumOrderValue } from './models/minimum-order-value.model';
import { UpdateMinimumOrderValueRequest } from './models/update-miminum-order-value.model';
import { Area, Areas } from './models/area.model';

@Injectable({ providedIn: 'root' })
class ModuleService {
  constructor(private readonly _http: HttpClient) {}

  public fetchModules(businessId: number): Observable<Module[]> {
    return this._http
      .get(`${environment.API_URL}/BusinessServiceTypes/${businessId}`)
      .pipe(
        take(1),
        shareReplay(1),
        map((modules) => ObjectMapper.deserializeArray(Module, modules))
      );
  }

  public updateOrderingTimes(params: any): Observable<void> {
    return this._http
      .put<void>(`${environment.API_URL}/OrderingTimes`, params)
      .pipe(take(1), shareReplay(1));
  }

  public updatePreparationTimes(params: any): Observable<void> {
    return this._http
        .put<void>(`${environment.API_URL}/PreparationTimes`, params)
        .pipe(take(1), shareReplay(1));
  }

  public updateModule(
    businessId: number,
    params: UpdateModule
  ): Observable<void> {
    return this._http
      .put<void>(
        `${environment.API_URL}/BusinessServiceTypes/${businessId}`,
        params
      )
      .pipe(take(1), shareReplay(1));
  }

  public fetchOrderingTimes({
    businessId,
    serviceType,
  }: {
    businessId: number;
    serviceType: string;
  }): Observable<OrderingTime[]> {
    return this._http
      .get<OrderingTime[]>(
        `${environment.API_URL}/OrderingTimes/business/${businessId}/servicetype/${serviceType}`
      )
      .pipe(
        take(1),
        shareReplay(1),
        map((orderingTimes) =>
          ObjectMapper.deserializeArray(OrderingTime, orderingTimes)
        )
      );
  }

  public fetchPreparationTimes({
     businessId,
     serviceType,
  }: {
    businessId: number;
    serviceType: string;
  }): Observable<PreparationTime[]> {
    return this._http
        .get<PreparationTime[]>(
            `${environment.API_URL}/PreparationTimes/business/${businessId}/servicetype/${serviceType}`
        )
        .pipe(
            take(1),
            shareReplay(1),
            map((preparationTimes) =>
                ObjectMapper.deserializeArray(PreparationTime, preparationTimes)
            )
        );
  }

  public fetchDeliveryZones({
    businessId
  }: {
    businessId: number;
  }): Observable<DeliveryZone[]> {
    return this._http
      .get<DeliveryZone[]>(
        `${environment.API_URL}/DeliveryZones/${businessId}`
      )
      .pipe(
        take(1),
        shareReplay(1),
        map((deliveryZones) =>
          ObjectMapper.deserializeArray(DeliveryZone, deliveryZones)
        )
      );
  }

  public addDeliveryZone(params: UpdateDeliveryZoneRequest): Observable<DeliveryZone> {
    return this._http
        .post<DeliveryZone>(`${environment.API_URL}/DeliveryZones`, params)
        .pipe(take(1), shareReplay(1));
  }

  public updateDeliveryZone(params: UpdateDeliveryZoneRequest): Observable<DeliveryZone> {
    return this._http
        .put<DeliveryZone>(`${environment.API_URL}/DeliveryZones`, params)
        .pipe(take(1), shareReplay(1));
  }

  public deleteDeliveryZone(deliveryZoneId: number): Observable<DeliveryZone> {
    return this._http
        .delete<DeliveryZone>(`${environment.API_URL}/DeliveryZones/${deliveryZoneId}`)
        .pipe(take(1), shareReplay(1));
  }

  public fetchMinimumOrderValue({
    businessId,
    serviceTypeName
  }: {
    businessId: number;
    serviceTypeName: string;
  }): Observable<MinimumOrderValue> {
    return this._http
      .get<MinimumOrderValue>(
        `${environment.API_URL}/MinimumOrderValues/${businessId}/${serviceTypeName}`
      )
      .pipe(
        take(1),
        shareReplay(1)
      );
  }

  public addMinimumOrderValue(params: UpdateMinimumOrderValueRequest): Observable<MinimumOrderValue> {
    return this._http
        .post<MinimumOrderValue>(`${environment.API_URL}/MinimumOrderValues`, params)
        .pipe(take(1), shareReplay(1));
  }

  public updateMinimumOrderValue(params: UpdateMinimumOrderValueRequest): Observable<MinimumOrderValue> {
    return this._http
        .put<MinimumOrderValue>(`${environment.API_URL}/MinimumOrderValues`, params)
        .pipe(take(1), shareReplay(1));
  }

  public deleteMinimumOrderValue(minimumOrderValueId: number): Observable<MinimumOrderValue> {
    return this._http
        .delete<MinimumOrderValue>(`${environment.API_URL}/MinimumOrderValues/${minimumOrderValueId}`)
        .pipe(take(1), shareReplay(1));
  }

  public fetchAreas(
    businessId: number,
    serviceType: string,
    currentPage?: number,
    pageSize?: number
  ): Observable<Areas> {
    var params = currentPage && pageSize ? `?pageNumber=${currentPage}&pageSize=${pageSize}` : '';
    return this._http.get<Areas>(
      `${environment.API_URL}/Areas/${businessId}/${serviceType}${params}`
    );
  }

  public fetchUnassignedAreas(
    businessId: number,
    serviceType: string
  ): Observable<Area[]> {
    return this._http.get<Area[]>(
      `${environment.API_URL}/Areas/unassigned/${businessId}/${serviceType}`
    );
  }

  public updateArea(params: Area): Observable<Area> {
    return this._http
        .put<Area>(`${environment.API_URL}/Areas`, params)
        .pipe(take(1), shareReplay(1));
  }

  public addArea(params: Area): Observable<Area> {
    return this._http
        .post<Area>(`${environment.API_URL}/Areas`, params)
        .pipe(take(1), shareReplay(1));
  }

}

export default ModuleService;
