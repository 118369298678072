export const getUserId = (): string => {
  const userId = localStorage.getItem('userIdStore');
  if (!userId) throw new Error('No userId detected');
  return userId;
};

export const getBusinessId = (): number => {
  const businessId = Number(localStorage.getItem('businessIdStore'));
  if (!businessId) throw new Error('No businessId detected');
  return businessId;
};

/**
 * @function
 * This function checks if a color string is a valid hex value
 * */
export const isValidHex = (color: string): boolean => {
  return /[\dA-Fa-f]{6}/g.test(color);
};
