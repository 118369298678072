<mat-sidenav-container style="overflow-x: hidden">
  <div>
    <app-mobile-nav *ngIf="false" [navItems]="navItems"></app-mobile-nav>
    <app-side-nav
      *ngIf="showMenu"
      [navItems]="navItems"
      [bottomNavItems]="bottomNavItems"
    ></app-side-nav>

    <div class="flex flex-col flex-1" [ngClass]="{ 'md:pl-64': showMenu }">
        <sidebar-settings *ngIf="showDrawer" #drawer></sidebar-settings>
        <app-header (showDrawer)="handleDrawer()"></app-header>
        <app-main [pageTitle]="titleService.getTitle()">
          <ng-content></ng-content>
        </app-main>
    </div>
  </div>
</mat-sidenav-container>
