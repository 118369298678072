import { Injectable } from '@angular/core';
import { Area } from 'apps/orderadmin/src/app/modules/main/pages/modules/models/area.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidebarSettingsService {
  private dataSubject = new BehaviorSubject<Area[]>([]);
  $data = this.dataSubject.asObservable();

  setData(data: Area[]): void {
    localStorage.setItem('sidebarAreas', JSON.stringify(data));
    this.dataSubject.next(data);
  }
}
