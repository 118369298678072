<!-- form-wrapper.component.html -->
<div class="mat__card form-wrapper">
  <mat-card>
    <div class="form-header">
      <div class="form-header-titles">
        <mat-card-title class="form-wrapper__title" *ngIf="title">{{
          title
        }}</mat-card-title>
        <p class="form-wrapper__subtitle" *ngIf="subtitle">{{ subtitle }}</p>
      </div>
      <div class="form-header-actions">
        <ng-content select="[header-actions]"></ng-content>
      </div>
    </div>

    <mat-card-content>
      <ng-content></ng-content>
    </mat-card-content>

    <mat-card-actions>
      <!-- //TODO replace with mat button -->
      <button
        class="cancel-button"
        *ngIf="showCancel"
        (click)="onCancel.emit()"
      >
        {{ 'global.cancelButton' | translate }}
      </button>

      <button
        *ngIf="hasAction"
        [disabled]="isDisabled"
        color="primary"
        mat-raised-button
        [attr.aria-label]="'Save Button'"
        type="submit"
        (click)="onSave.emit()"
      >
        {{ 'global.SaveButton' | translate }}
      </button>
    </mat-card-actions>
  </mat-card>
</div>
