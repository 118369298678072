import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { SnackbarService } from './snack-bar.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private readonly _ngZone: NgZone,
    private readonly _snackbarService: SnackbarService
  ) {}

  public handleError(error: any) {
    console.log(error);
    this._ngZone.run(() => {
      if (document.location.host.includes('localhost')) {
        console.log(error);
      }
      this._snackbarService.trigger(
        error?.errorMessage ||
          'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut',
        'x',
        {
          panelClass: 'error-snackbar',
        }
      );
    });
  }
}
