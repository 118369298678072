import { JsonProperty } from 'json-object-mapper';

export class BusinessSubcategory {
  @JsonProperty() public businessTypeCategoryId: number;
  @JsonProperty() public businessTypeId: number;
  @JsonProperty() public name: string;

  constructor() {
    this.businessTypeCategoryId = null as unknown as number;
    this.businessTypeId = null as unknown as number;
    this.name = '';
  }
}
