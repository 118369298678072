import { JsonProperty } from 'json-object-mapper';
import { Country } from './country.model';

export class Address {
  @JsonProperty() public createdDate: Date | null;
  @JsonProperty() public modifiedDate: Date | null;
  @JsonProperty() public addressId: number | null;
  @JsonProperty() public countryId: number;
  @JsonProperty() public street: string;
  @JsonProperty() public postalCode: string;
  @JsonProperty() public city: string | null;
  @JsonProperty({ type: Country }) public country: Country | null;

  constructor() {
    this.createdDate = null;
    this.modifiedDate = null;
    this.addressId = 0;
    this.countryId = 0;
    this.street = '';
    this.postalCode = '';
    this.city = '';
    this.country = null;
  }
}
