<h2 mat-dialog-title>{{data.title}}</h2>
<div mat-dialog-content>
    {{data.text}}
</div>
<br>
<br>
<div class="prompt-buttons" mat-dialog-actions>
    <button mat-button mat-dialog-close>Abbrechen</button>
    <button mat-button cdkFocusInitial color="warn" (click)="proceed()">{{data.ok || 'Weiter'}}</button>
</div>
