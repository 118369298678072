import { Variant } from "../../../../../../business/models/product.model";

export class Menus {
  public menuTypes: Menu[];

  constructor() {
    this.menuTypes = [];
  }
}

export class Menu {
  public id: string;
  public name: string;
  public menuProducts: MenuProducts[];

  constructor() {
    this.id = '';
    this.name = '';
    this.menuProducts = [];
  }
}

export class MenuProducts {
  public name: string;
  public categoryOrder: number;
  public products: Products[];
  public productType: string;

  constructor() {
    this.name = '';
    this.productType = '';
    this.categoryOrder = 1;
    this.products = [];
  }
}

export class Products {
  public id: number;
  public imageUrl: string;
  public name: string;
  public price: string;
  public originalPrice: string;
  public productOrder: number;
  public variants: Variant[];

  constructor() {
    this.id = 0;
    this.imageUrl = '';
    this.name = '';
    this.price = '';
    this.originalPrice = '';
    this.productOrder = 0;
    this.variants = [];
  }
}
