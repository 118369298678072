import { JsonProperty } from 'json-object-mapper';
import { TimeEvent } from './time-event.model';

export class OrderingTime {
  @JsonProperty() public day: string;
  @JsonProperty() public dayId: number;
  @JsonProperty() public isOpen: boolean;
  @JsonProperty() public durations: TimeEvent[];

  constructor() {
    this.day = '';
    this.dayId = null as unknown as number;
    this.isOpen = false;
    this.durations = [];
  }
}
