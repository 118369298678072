import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatButton } from '@angular/material/button';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import BusinessFacade from 'apps/orderadmin/src/app/modules/business/business.facade';
import { BusinessConfigurationState } from '../../core/states/business-configuration.state';
import {
  DashboardConfiguration,
  BusinessConfiguration,
} from '../../core/models/business-configuration.model';
import {
  Area,
  Areas,
} from 'apps/orderadmin/src/app/modules/main/pages/modules/models/area.model';
import ModuleService from '../../../../../../apps/orderadmin/src/app/modules/main/pages/modules/module.service';
import { serviceTypesData } from '../../../../../../apps/orderadmin/src/app/constants/service-type-data';
import { SidebarSettingsService } from '../../core/services/side-bar-settings.service';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'sidebar-settings',
  templateUrl: './sidebar-settings.component.html',
  styleUrls: ['./sidebar-settings.component.scss'],
})
export class SidebarSettingsComponent implements OnInit {
  @ViewChild('sideDrawer', { static: true }) public sideDrawer!: MatSidenav;
  @ViewChild('select') select!: MatSelect;
  constructor(
    private readonly _businessFacade: BusinessFacade,
    private _businessConfigurationState: BusinessConfigurationState,
    private readonly _moduleService: ModuleService,
    private readonly _sidebarService: SidebarSettingsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}
  extraSettingsValue = false;
  businessId = 0;

  qrCodeAreas: Area[] = [];
  selectedAreas: Area[] = [];
  ngOnInit(): void {
    this.businessId = this._businessFacade.getCurrentBusinessId() || 0;
    this._businessConfigurationState
      .getBusinessConfiguration(this.businessId)
      .subscribe((config) => {
        if (config?.dashboardConfiguration?.displayExtraTitles) {
          this.extraSettingsValue =
            config.dashboardConfiguration.displayExtraTitles || false;
        }
      });
    this.getAllQrCodeArea();
  }

  public toggle() {
    this.sideDrawer.toggle();
  }
  closeSideDrawer() {
    this.sideDrawer.close();
  }

  onStatusChange(value: MatSlideToggleChange) {
    if (this.businessId !== null) {
      this._businessConfigurationState
        .getBusinessConfiguration(this.businessId)
        .subscribe((config) => {
          if (config) {
            config.dashboardConfiguration.displayExtraTitles = value.checked;
            this._businessConfigurationState.setBusinessConfiguration(config);
          }
        });
    }
  }

  getAllQrCodeArea() {
    this._businessFacade.businessId$.data$.subscribe((businessId) => {
      this._moduleService
        .fetchAreas(businessId as number, serviceTypesData.Inhouse.name, 1, 100)
        .subscribe((response: Areas) => {
          this.qrCodeAreas = response.areas;
          this.handleSelectedQrCodeArea();
        });
    });
  }
  onQrCodeAreaChange($event: any) {
    this._sidebarService.setData($event.value);
  }

  handleSelectedQrCodeArea() {
    this._sidebarService.$data.subscribe((data) => {
      this.selectedAreas = [];
      const selectedQrCodes = this.qrCodeAreas.filter((area) =>
        data.some((a) => area.areaId == a.areaId)
      );
      this.selectedAreas.push(...selectedQrCodes);
    });
  }
}
