import { Component, Input, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BottomNavItem, NavItem } from '@shared-ui';
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  @Input() public navItems: NavItem[] = [];
  @Input() public bottomNavItems: BottomNavItem[] = [];
  showDrawer = false;
  drawer!: MatDrawer;
  @ViewChild('drawer', { static: false }) set setDrawer(ref: MatDrawer) {
    this.drawer = ref;
  }
  public showMenu = true;
  constructor(public readonly titleService: Title, private router: Router) {
    if (
      this.router.url.includes('/orders-dashboard') ||
      this.router.url.includes('/waiterapp')
    ) {
      this.showMenu = false;
    }
    this.showDrawerDashboard();
  }

  handleDrawer() {
    this.drawer?.toggle();
  }
  showDrawerDashboard() {
    if (this.router.url.includes('/orders-dashboard')) {
      this.showDrawer = true;
    }
  }
}
