<!-- Dashboard body -->
<main class="flex-1">
  <div class="py-6 page-body" [ngClass]="{ 'no-title': IsDashBoard }">
    <div class="page-body__section" *ngIf="!IsDashBoard">
      <h1 class="page-body__title">{{ pageTitle | translate }}</h1>
    </div>

    <div class="page-body__section">
      <div class="page-body__main">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</main>
