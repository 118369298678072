import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'input-counter',
  templateUrl: './input-counter.component.html',
  styleUrls: ['./input-counter.component.scss'],
})
export class InputCounterComponent implements OnInit {
  @Input() counter = 1;
  @Input() size: string = '';
  @Output() public handleDecrease = new EventEmitter();
  @Output() public handleIncrease = new EventEmitter();
  @Input() minValue = 1;
  @Input() maxValue = 100;
  reachedLimitIncrease = false;
  reachedLimitDecrease = false;

  constructor() {}

  ngOnInit(): void {}

  decrease() {
    if (this.counter !== this.minValue) {
      this.reachedLimitIncrease = this.reachedLimitIncrease ? false : false;
      this.counter = this.counter - 1;
      if (this.counter === this.minValue) {
        this.reachedLimitDecrease = true;
      }
      this.handleDecrease.emit();
    }
  }

  increase() {
    if (this.counter < this.maxValue) {
      this.reachedLimitDecrease = this.reachedLimitDecrease ? false : false;
      this.counter = this.counter + 1;
      if (this.counter === this.maxValue) {
        this.reachedLimitIncrease = true;
      }
      this.handleIncrease.emit();
    }
  }
}
