import { Component, Input } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'jamatu-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() public readonly mode: ProgressSpinnerMode = 'indeterminate';
  @Input() public readonly value = 50;
  @Input() public readonly diameter = 50;
  @Input() public readonly color = 'primary';
}
