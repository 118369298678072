import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CreateBusinessComponent } from './modules/business/create-business/create-business.component';
import {NotFoundComponent} from "./modules/not-found/not-found.component";
import {NoAccessComponent} from "./modules/no-access/no-access.component";

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'create-business',
    component: CreateBusinessComponent,
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
