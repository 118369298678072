<ng-container>
    <div class="not-found">
        <div class="not-found__image">
            <img
                class="header__logo"
                [src]="'../../assets/images/404.svg'"
                alt="order app logo"
            />
        </div>
        <div class="not-found__title">
            Seite nicht gefunden
        </div>
        <div class="not-found__subtitle">
            Die von dir angeforderte Seite konnte nicht gefunden werden.
        </div>
        <div class="not-found__back">
            <button (click)="goBack()">Zurück</button>
        </div>
    </div>
</ng-container>
