import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  @Input() public pageTitle = '';

  constructor() {}

  IsDashBoard() {
    return this.pageTitle.indexOf('Order Manager') > -1;
  }
}
