import { JsonProperty } from 'json-object-mapper';

export class Country {
  @JsonProperty() public countryId: number;
  @JsonProperty() public name: string;
  @JsonProperty() public isHighlighted: boolean;
  @JsonProperty() public isLastHighlighted: boolean;

  constructor() {
    this.countryId = null as unknown as number;
    this.name = '';
    this.isHighlighted = false;
    this.isLastHighlighted = false;
  }

  public get text(): string {
    return this.name;
  }

  public get value(): number {
    return this.countryId;
  }
}
