import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, shareReplay, take } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CreateBusiness } from './models/create-business.model';
import { ObjectMapper } from 'json-object-mapper';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { BusinessType, Country } from '@shared-ui';
import { MenuProducts } from './models/menu-products.model';
import { Menu } from '../main/pages/menu/components/overview-menus/models/menus.model';

@Injectable({ providedIn: 'root' })
class BusinessService {
  constructor(private readonly _http: HttpClient) {}

  public getCountries(): Observable<Country[]> {
    return this._http.get<Country[]>(`${environment.API_URL}/Countries`).pipe(
      shareReplay(1),
      map((countries) => {
        const isHighlighted = countries
          .filter((country) => country.isHighlighted)
          .reverse();
        isHighlighted[isHighlighted.length - 1].isLastHighlighted = true;
        const isNotHighlighted = countries.filter(
          (country) => !country.isHighlighted
        );

        return ObjectMapper.deserializeArray(Country, [
          ...isHighlighted,
          ...isNotHighlighted,
        ]);
      })
    );
  }

  public getBusinessTypes(): Observable<BusinessType[]> {
    return this._http
      .get<BusinessType[]>(`${environment.API_URL}/BusinessTypes`)
      .pipe(
        shareReplay(1),
        map((businessTypes) =>
          ObjectMapper.deserializeArray(BusinessType, businessTypes)
        )
      );
  }

  public getBusinessId(businessUserId: string) {
    return this._http.get(
      `${environment.API_URL}/Businesses/${businessUserId}/user`
    );
  }

  public createBusiness(params: CreateBusiness): Observable<number> {
    return this._http
      .post<{ businessId: number }>(`${environment.API_URL}/Businesses`, params)
      .pipe(
        shareReplay(1),
        map(({ businessId }) => businessId)
      );
  }

  public fetchMenuProducts({
    businessId,
    menuId
  }: {
    businessId: number;
    menuId: number;
  }): Observable<Menu> {
    return this._http
      .get<Menu>(`${environment.API_URL}/Products/by-categories/business/${businessId}/menu/${menuId}`)
      .pipe(
        take(1),
        shareReplay(1),
        map((data) => data)
      );
  }
}

export default BusinessService;
