import { JsonProperty } from 'json-object-mapper';

export class PreparationTime {
  @JsonProperty() public statusName: string;
  @JsonProperty() public minutes: number;
  @JsonProperty() public orderLimit: number;

  constructor() {
    this.statusName = '';
    this.minutes = 30;
    this.orderLimit = 10;
  }
}
