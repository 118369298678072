import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'orderadmin-form-wrapper',
  templateUrl: './form-wrapper.component.html',
  styleUrls: ['./form-wrapper.component.scss'],
})
export class FormWrapperComponent {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() isDisabled: boolean | null = true;
  @Output() onSave = new EventEmitter();
  @Input() hasAction = true;
  @Input() showCancel = false;
  @Output() onCancel = new EventEmitter();
}
